<template>
  <div class="">
    <div class="pt-1 pb-1">
      <div class="col-lg">
        <div class="card" style="">
          <b-tabs
            v-if="isCreate"
            v-model="tabIndex"
            class="saj-title p-1"
            style="width: 100%"
          >
            <b-tab :title="$t('Information')" active>
              <information :subs-i-d="id" @info-required="isWork = false" />
            </b-tab>
            <b-tab :disabled="isWork" :title="$t('Work Location')">
              <workLocation @work-location="isDept = false" />
            </b-tab>
            <b-tab :disabled="isDept" :title="$t('Department')">
              <department @dept-info="isEmployee = false" />
            </b-tab>
            <!-- <b-tab
              :disabled="isPosition"
              :title="$t('Position')"
            >
              <position @position-info="isEmployee = false" />
            </b-tab> -->
            <b-tab :disabled="isEmployee" :title="$t('Employee')">
              <employee :subs-i-d="id" />
            </b-tab>
          </b-tabs>
          <b-tabs
            v-else
            v-model="tabIndex"
            class="saj-title p-1"
            style="width: 100%"
          >
            <b-tab :title="$t('Information')" active>
              <information :subsidiary-name="subsName" :subs-i-d="id" />
            </b-tab>
            <b-tab :title="$t('Work Location')">
              <workLocation :subsidiary-name="subsName" />
            </b-tab>
            <b-tab :title="$t('Department')">
              <department :subsidiary-name="subsName" />
            </b-tab>
            <!-- <b-tab :title="$t('Position')">
              <position :subsidiary-name="subsName" />
            </b-tab> -->
            <b-tab :title="$t('Employee')">
              <employee :subs-i-d="id" :subsidiary-name="subsName" />
            </b-tab>
          </b-tabs>
          <!-- Control buttons-->
          <div class="d-flex justify-content-end mb-1 mr-2 mt-1">
            <b-button-group class="mb-1">
              <b-button
                v-if="tabIndex !== 0"
                class=""
                style="
                  background: #ffffff !important;
                  border-color: red !important;
                  color: red !important;
                "
                @click="tabIndex--"
              >
                {{ $t("Back") }}
              </b-button>
              <b-button
                v-if="tabIndex !== 3"
                class="ml-1"
                variant="primary"
                @click="goToNextPage()"
              >
                {{ $t("Next Page") }}
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BButtonGroup, BTabs, BTab } from "bootstrap-vue";
import { required } from "@validations";
import information from "@/component/register-component/information.vue";
import workLocation from "@/component/register-component/work-location.vue";
import department from "@/component/register-component/department.vue";
// import position from '@/component/register-component/position.vue'
import employee from "@/component/register-component/employee.vue";
import SAJToast from "@/component/saj-toastification.vue";

export default {
  components: {
    BButton,
    BButtonGroup,
    BTabs,
    BTab,
    information,
    workLocation,
    department,
    // position,
    employee,
  },
  props: {},
  data() {
    return {
      required,
      tabIndex: 1,
      id: null,
      isWork: true,
      isDept: true,
      isPosition: true,
      isEmployee: true,
      isCreate: false,
      subsName: "",
    };
  },
  mounted() {},
  beforeMount() {
    this.id = this.$route.params.id.toString();
    this.isCreate = this.$route.params.isCreate;
    this.subsName = this.$route.params.subsidiaryname;
  },

  methods: {
    goToNextPage() {
      const nextPage = this.tabIndex == 0 ? this.isWork : this.tabIndex == 1 ? this.isDept : this.isEmployee
      if (nextPage) {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t("Please add information first")}.`,
              icon: "XIcon",
              iconBg: "#e80202",
              variant: "danger",
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: "error",
          }
        );
      }
      this.tabIndex++;
    },
  },
};
</script>
<style></style>
