<template>

  <div
    class="p-1 modalScroll"
    style="width: auto; height: 55vh;"
  >
    <div
      class="mb-1 saj-title d-flex justify-content-start"
      style=""
    >
      {{ $t ("Department Info") }}
    </div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-form-group
          class="saj-text"
          :label="$t('Department Name')"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Department Name')"
            rules="required"
          >
            <!-- {{ deptData }} -->
            <!-- {{ departmentData }} -->
            <b-form-input
              v-model="name"
              class="saj-form-text"
              :readonly="roles.isHR"
              :placeholder="$t('Enter department')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
    <!-- <validation-observer ref="simpleRules"> -->
    <b-form class="mt-2">
      <b-form-group
        class="saj-text"
        :label="$t('Description')"
      >
        <!-- <validation-provider
            #default="{ errors }"
            :name="$t('Work Location Name')"
            rules="required"
          > -->
        <b-form-textarea
          v-model="details"
          class="saj-form-text"
          :placeholder="$t('Description')"
          :readonly="roles.isHR"
          rows="6"
        />
        <!-- <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider> -->
      </b-form-group>
    </b-form>
    <!-- </validation-observer> -->
    <div
      class="d-flex justify-content-end"
      style=""
    >
      <button
        class="btn btn-close mt-1 ml-1 mb-1 saj-button"
        aria-label="Close modal"
        style="
                  color: white;
                  background: #ff0000;
                "
        @click="close()"
      >
        {{ $t('Back') }}
      </button>
      <button
        class="btn mt-1 ml-1 mb-1 saj-button"
        aria-label="submit modal"
        style="
                  color: white;
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
        :style="roles.isHR ? `display: none;` : ``"
        @click="isUpdate ? updateDept() : createDept()"
      >
        {{ $t('Save') }}
      </button>
    </div>
    <!-- </validation-observer> -->
  </div>

</template>
<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    // BButton,
    // BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    // BInputGroup,
    // BFormGroupAppend,
    // BCard,
    // BFormTimepicker,
    // BCardCode,
    // BRow,
    // BCol,
    // BCalendar,
    // BCardText,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    deptData: {
      type: Object,
      default: () => {},
    },

    isUpdate: {
      type: Boolean,
      default: false,
    },

    deptId: {
      type: Number,
      default: null,
    },

  },

  data() {
    return {
      // departmentData: {},
      name: this.deptData !== undefined ? this.deptData.name : null,
      // eslint-disable-next-line no-nested-ternary
      details: this.deptData !== undefined ? this.deptData.details !== 'null' ? this.deptData.details : '-' : null,
      required,
    }
  },

  computed: {
    ...mapGetters(['roles']),
  },

  beforeMount() {
    this.id = this.$route.params.id
  },
  mounted() {
    // this.departmentData = this.deptData
    // console.log("deptData", this.departmentData)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    createDept() {
      this.$refs.simpleRules.validate().then(success => {
        if (success){
          const data = new FormData()
          data.append('subsidiary_id', this.id)
          data.append('name', this.name)
          data.append('details', this.details)

          this.$axios.post(`${this.$baseUrl}/departments/store_department`, data).then(() => {
            this.$emit('close')
            this.$emit('add-department')
            setTimeout(() => {
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t('Department successfully added')}.`,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
            }, 500)
          })
        }
      })
    },
    updateDept() {
      const data = new FormData()
      data.append('department_id', this.deptData.id)
      data.append('name', this.name)
      data.append('details', this.details === "" ? '-' : this.details)

      this.$axios.post(`${this.$baseUrl}/departments/update_department`, data).then(() => {
        this.$emit('close')
        this.$emit('update-department')
        setTimeout(() => {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Department info successfully updated')}.`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }, 500)
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>
