<template>
  <div class="">
    <div class="row">
      <div class="col-lg">
        <div
          class="card"
          style="
            "
        >
          <div class="saj-header">
            {{ subsidiaryName }}
          </div>
          <div class="pl-3 pr-3 pb-2 pt-2">
            <!-- new template basic info-->
            <div
              class="row pt-1 pb-2"
              style="
              background: none;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 10px;"
            >
              <div class="col">
                <div
                  class="row pl-1"
                >
                  <div
                    class="saj-title mb-1"
                  >
                    {{ $t("Basic Information") }}<br>
                    <!-- subsidiary ID: {{ subsidiaryID }} -->
                  </div>
                </div>
                <div class="mb-1 saj-title d-flex justify-content-end">
                  <b-button
                    class="ml-1 saj-button"
                    variant="primary"
                    style=""
                    :style="roles.isHR ? `display: none;` : ``"
                    @click="updateInformation()"
                  >
                    {{ $t("Add Information") }}
                  </b-button>
                </div>
                <validation-observer ref="validateInformation">
                  <div
                    class="row mb-1"
                  >
                    <div class="col-lg saj-text">
                      <div class="m-0 mt-1 mb-1">
                        {{ $t ("Company Name") }} :
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Company Name')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="company.company_name"
                          size="lg"
                          class="saj-text"
                          :placeholder="$t('Name')"
                          :readonly="roles.isHR"
                          rows="8"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </div>

                  <div
                    class="row"
                  >
                    <div class="col-lg-6 saj-text">
                      <div class="m-0 mt-1 mb-1">
                        {{ $t ("Registration Number") }} :
                      </div>
                      <b-form-input
                        v-model="company.registration_number"
                        :placeholder="$t('Registration Number')"
                        size="lg"
                        class="saj-text"
                        :readonly="roles.isHR"
                        rows="8"
                      />
                    </div>
                    <!-- <div class="col-lg-6 saj-text">
                      <div class="m-0 mt-1 mb-1">
                        {{ $t ("Incorporate Date") }} :
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Incorporate Date')"
                        rules="required"
                      >
                        <b-form-group>
                          <flat-pickr
                            v-model="company.incorporate_date"
                            class="saj-form-text form-control "
                            style="background: #fff; height: 3.25rem;"
                            size="lg"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div> -->
                    <div class="col-lg-6 saj-text">
                      <div class="m-0 mt-1 mb-1">
                        {{ $t ("Email") }} :
                      </div>
                      <b-form-input
                        v-model="company.email"
                        :placeholder="$t('Email')"
                        size="lg"
                        class="saj-form-text"
                        :readonly="roles.isHR"
                        rows="8"
                      />
                    </div>
                  </div>

                  <div
                    class="row"
                  >
                    <div class="col-lg-6 saj-text">
                      <div class="m-0 mt-1 mb-1">
                        {{ $t ("Office Contact Number") }} :
                      </div>
                      <b-form-input
                        v-model="company.ocn"
                        :placeholder="$t('Office Contact Number')"
                        size="lg"
                        class="saj-text"
                        :readonly="roles.isHR"
                        rows="8"
                      />
                    </div>
                    <div class="col-lg-6 saj-text">
                      <div class="m-0 mt-1 mb-1">
                        {{ $t ("Website Link") }} :
                      </div>
                      <b-form-input
                        v-model="company.website_link"
                        :placeholder="$t('Website Link')"
                        size="lg"
                        class="saj-text"
                        :readonly="roles.isHR"
                        rows="8"
                      />
                    </div>
                  </div>

                  <div
                    class="row"
                  >
                    <div class="col-lg-6 saj-text">
                      <div class="m-0 mt-1 mb-1">
                        {{ $t ("Description") }} :
                      </div>
                      <b-form-input
                        v-model="company.description"
                        :placeholder="$t('Description')"
                        size="lg"
                        class="saj-text"
                        :readonly="roles.isHR"
                        rows="8"
                      />
                    </div>
                    <!-- {{ company.logo }} logo -->
                    <div class="col-lg-6 saj-text">
                      <div class="m-0 mt-1 mb-1 ">
                        {{ $t ("Logo") }} :
                      </div>
                      <b-form-file
                        v-model="tempFile"
                        type="file"
                        class="mt-1 saj-text"
                        :disabled="roles.isHR"
                        :browse-text="$t('Choose File')"
                        :placeholder="$t('No file choosen')"
                        plain
                        @change="onFileChange"
                      />
                    </div>
                    <div class="col-lg-6 saj-text d-flex align-items-end" />
                    <div class="col-lg-6 saj-text d-flex align-items-end pl-1">
                      <div
                        v-if="company.logo === null"
                        class="saj-text col align-items-center"
                        style=""
                      >
                        <feather-icon
                          class=""
                          icon="PaperclipIcon"
                          size="16"
                        />
                        {{ $t("No File") }}
                      </div>
                      <div
                        v-if="company.logo !== null"
                        class="saj-text col align-items-center"
                        style=""
                      >
                        <feather-icon
                          class=""
                          icon="PaperclipIcon"
                          size="16"
                        />
                        <a
                          :href="company.logo.url"
                          target="_blank"
                        >
                          {{ $t(company.logo.name) }}
                        </a>
                      </div>

                    </div>
                  </div>
                </validation-observer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {
  BFormInput,
  BButton,
  BFormFile,

} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import flatPickr from "vue-flatpickr-component"
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    // flatPickr,
  },
  props: {
    subsID: {
      type: String,
      default: "",
    },
    subsidiaryName: {
      type: String,
      default: "",
    },

  },
  data(){
    return {
      subsidiaryID: null,
      required,
      hq: {
        building: "",
        postcode: "",
        street: "",
        city: "",
        state: "",
        country: "",
      },
      tempFile: null,
      company: {
        company_name: "",
        registration_number: "",
        incorporate_date: "",
        ocn: "",
        website_link: "",
        description: "",
        logo: [],
        email: "",
      },
    }
  },
  computed: {
    ...mapGetters(['roles']),
  },
  mounted() {
    this.subsidiaryID = this.subsID
    if (this.subsID !== null){
      this.getInformation()
    }
    // console.log('subs info', this.subsidiaryName)
  },
  methods: {
    onFileChange(e) {
      // console.log('on file change', e.target.files[0].size)
      if (e.target.files.length !== 0){
        const fileSize = e.target.files[0].size / 1000

        if (fileSize > 5120){
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('File must less than 5 MB')}.`,
                icon: "XIcon",
                variant: "success",
                titleColor: "#000",
                iconBg: '#e80202',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
      }
    },
    updateInformation(){
      this.$refs.validateInformation.validate().then(success => {
        if (success){
          const data = new FormData()
          data.append('id', this.subsidiaryID)
          data.append('logo', this.tempFile)
          data.append('subsidiary_name', this.company.company_name)
          data.append('email', this.company.email)
          data.append('website', this.company.website_link)
          data.append('ssm', this.company.registration_number)
          data.append('phone', this.company.ocn)
          data.append('description', this.company.description)
          this.$emit('info-required')
          this.$axios.post(`${this.$baseUrl}/subsidiary/update`, data)
            .then(() => {
              setTimeout(() => {
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$i18n.t(`Information successfully updated`)}`,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      titleColor: '#000',
                    },
                  },
                  {
                    position: "top-right",
                    type: 'info',
                  },
                )
              }, 1000)
              this.getInformation()
            })
          // break
        }
      })
    },
    getInformation(){
      const config = {
        method: 'get',
        url: `${this.$baseUrl}/subsidiary/getById?id=${this.subsidiaryID}`,
      }

      this.$axios(config)
        .then(response => {
          // this.getInformation()
          // eslint-disable-next-line prefer-destructuring
          this.informationList = response.data.data[0]
          // console.log('file', this.company)
          this.company.company_name = this.informationList.subsidiary_name
          this.company.registration_number = this.informationList.ssm === 'null' || this.informationList.ssm === null ? '-' : this.informationList.ssm
          this.company.ocn = this.informationList.phone === 'null' || this.informationList.phone === null ? '-' : this.informationList.phone
          this.company.website_link = this.informationList.website === 'null' || this.informationList.website === null ? '-' : this.informationList.website
          this.company.description = this.informationList.description === 'null' || this.informationList.description === null ? '-' : this.informationList.description
          this.company.logo = this.informationList.logo
          this.company.email = this.informationList.email === 'null' || this.informationList.email === null ? '-' : this.informationList.email
        })
        .catch(() => {
        })
    },
  },
}
</script>
<style>
</style>
