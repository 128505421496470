<template>
  <div class="">
    <div class="row">
      <div class="col-lg">
        <div
          class="card"
          style="
            "
        >
          <div class="saj-header">
            {{ subsidiaryName }}
          </div>
          <div class="">
            <!-- new template work location-->
            <div
              class="row pt-1 pb-2"
              style="
    background: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
            border-radius: 10px;"
            >
              <div class="col">
                <div
                  class="row mb-1"
                >
                  <div
                    class=" col-lg-6 saj-title d-flex justify-content-start"
                  >
                    {{ $t("Work Location Info") }}
                  </div>

                  <div
                    class="col-lg-6 saj-title d-flex justify-content-end"
                  >
                    <!-- v-b-modal.(nama modal id yg ditetapkan dibwh) untuk panggil UI modal -->

                    <b-form-input
                      v-model="searchWorkLocation"
                      class="mr-1"
                      style="width: 250px;"
                      :placeholder="$t('Search Work Location')"
                    />
                    <b-button
                      v-b-modal.new-work-location
                      variant="primary"
                      style=""
                      :style="roles.isHR ? `display: none;` : ``"
                      class="saj-button"
                    >
                      {{ $t("Add Work Location") }}
                    </b-button>
                  </div>
                </div>
                <!-- <div class="row mt-1 d-flex justify-content-center"> -->
                <b-overlay
                  :show="show"
                  rounded="sm"
                >
                  <template #overlay>
                    <div class="d-flex flex-column align-items-center justify-content-center">
                      <b-spinner
                        variant="primary"
                        label="Spinning"
                      />
                      <p class="mt-1">
                        {{ $t('Fetching Data') }}...
                      </p>
                    </div>
                  </template>
                  <b-table
                    class="styleTable"
                    style="font-size: 1rem;"
                    show-empty
                    :items="branchList"
                    :fields="fields"
                    label-size="lg"
                    bordered
                    :filter="searchWorkLocation"
                    :current-page="currentPage"
                    :per-page="perPage"
                    responsive
                    @filtered="onFiltered"
                  >
                    <template #head()="data">
                      <span
                        class="saj-text d-flex justify-content-center"
                      >{{ $t(data.label) }}</span>
                    </template>
                    <template #empty="">
                      <h4 style="text-align: center; font-style: italic">
                        {{ $t('There are no records to show') }}
                      </h4>
                    </template>
                    <template #emptyfiltered="">
                      <h4 style="text-align: center; font-style: italic">
                        {{ $t('There are no records matching your request') }}
                      </h4>
                    </template>
                    <template #cell(index)="data">
                      <div
                        class="saj-text d-flex justify-content-center"
                      >
                        {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage-1) *15) }}
                      </div>
                    </template>
                    <template #cell(name)="data">
                      <div
                        class="saj-text d-flex justify-content-start"
                      >
                        {{ data.item.name }}
                      </div>
                    </template>
                    <template #cell(details)="data">
                      <div
                        :class="data.item.details === 'null' || data.item.details === null ? `saj-text d-flex justify-content-start` : `saj-text d-flex justify-content-start`"
                      >
                        {{ data.item.details === 'null' || data.item.details === null ? "-" : data.item.details }}
                      </div>
                    </template>
                    <template #cell(action)="data">
                      <div
                        class="saj-text d-flex justify-content-center"
                        style=""
                      >
                        <!-- {{ data.item.id }} -->
                        <div v-if="roles.isAdmin">
                          <feather-icon
                            v-b-modal.update-work-location
                            v-b-tooltip.hover.top="$t('Edit')"
                            icon="EditIcon"
                            size="25"
                            style="color: green"
                            class=""
                            @click="branchData = data.item"
                          />
                        </div>
                        <div v-else>
                          <feather-icon
                            v-b-modal.update-work-location
                            v-b-tooltip.hover.top="$t('Edit')"
                            icon="EyeIcon"
                            size="25"
                            class=""
                            @click="branchData = data.item"
                          />
                        </div>

                        <feather-icon
                          v-b-modal.delete-branch
                          v-b-tooltip.hover.top="$t('Delete')"
                          icon="Trash2Icon"
                          size="25"
                          class="ml-1"
                          style="color: red"
                          :style="roles.isHR ? `display: none;` : ``"
                          @click="branchId = data.item.id"
                        />
                      </div>
                    </template>
                  </b-table>
                </b-overlay>
                <!-- </div> -->

              </div>
            </div>
            <!-- <div class="mb-1 saj-title d-flex justify-content-end">
              <b-button
                class=""
                variant="primary"
                style="
                background: #ffffff !important;
              border-color: red !important;
              color: red !important;
              "
              >
                {{ $t("Back") }}
              </b-button>
              <b-button
                class="ml-1"
                variant="primary"
                style="
              "
              >
                {{ $t("Next Step") }}
              </b-button>
            </div> -->
          </div>
          <b-modal
            id="new-work-location"
            :hide-header="true"
            :hide-footer="true"
            :centered="true"
            size="lg"
          >
            <!-- $bvModal.hide(nama modal id) untuk tutup modal-->
            <work-location
              @close="$bvModal.hide('new-work-location')"
              @add-worklocation="getBranch()"
            />
          </b-modal>
          <b-modal
            id="update-work-location"
            :hide-header="true"
            :hide-footer="true"
            :centered="true"
            size="lg"
          >
            <!-- $bvModal.hide(nama modal id) untuk tutup modal-->
            <work-location
              :branch-data="branchData"
              :is-update="true"
              :branch-id="branchId"
              @close="$bvModal.hide('update-work-location')"
              @update-worklocation="getBranch()"
            />
          </b-modal>
          <b-modal
            id="delete-branch"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
          >
            <delete-branch
              confirm-btn="Yes"
              cancel-btn="No"
              :title="$t('Are you sure to delete this work location?')"
              @cancel="$bvModal.hide('delete-branch')"
              @confirm="deleteBranch()"
            />
          </b-modal>
        </div>
      </div>

    </div>
    <b-row>
      <b-col>
        <span
          class="saj-text"
          align="start"
        >
          {{ $t("Showing") }} {{ rows > perPage*currentPage ? perPage*currentPage : rows }} {{ $t("from") }} {{ rows }} {{ $t("entries") }}
          <!-- {{ $t("Showing") }} 1 {{ $t("to") }} 2 {{ $t("of") }} 3 {{ $t("entries") }} -->
        </span>
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          pills
          aria-controls="employee-table"
          class="saj-text mb-1 justify-content-end"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div></template>

<script>
import {
  BButton,
  BTable,
  BModal,
  VBTooltip,
  BRow,
  BFormInput,
  BCol,
  BPagination,
  BOverlay,
  BSpinner,
} from "bootstrap-vue"
import workLocation from "@/component/work-location-modal.vue"
import deleteBranch from "@/pages/alert-modal.vue"
import { required } from '@validations'
import { mapGetters } from 'vuex'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BButton,
    BTable,
    BModal,
    workLocation,
    deleteBranch,
    BRow,
    BFormInput,
    BCol,
    BPagination,
    BOverlay,
    BSpinner,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    subsidiaryName: {
      type: null,
      default: null,
    },

  },
  data(){
    return {
      branchData: {},
      branchId: null,
      required,
      currentPage: 1,
      branchList: [],
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'name', label: 'Name', tdClass: 'text-center',
        },
        {
          key: 'details', label: 'Description', tdClass: 'text-center',
        },
      ],
      searchWorkLocation: null,
      perPage: 15,
      rows: null,
      show: true,
    }
  },

  computed: {
    ...mapGetters(['roles']),
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },

  beforeMount() {
    // console.log('sini haaa')
    this.id = this.$route.params.id
    // console.log('sini sub id tab work location:', this.id)
  },

  mounted(){
    this.getBranch()
    if (this.roles.selectedRole !== 6){
      const a = {
        key: 'action', label: 'Action', tdClass: 'text-center',
      }
      this.fields = this.fields.concat(a)
    }
    // console.log(this.roles.selectedRole)
  },

  methods: {
    onFiltered(filteredItems){
      this.rows = filteredItems.length
    },
    getBranch() {
      // const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/branch/get_branch_by_company?company_id=${this.id}`).then(response => {
        if (response.data.data.length !== 0){
          this.branchList = response.data.data
          this.$emit('work-location')
          this.show = false
          // this.rows = this.branchList.length
          // console.log(response)
        }
      }).catch(() => {
        this.branchList = []
        this.show = false
      })
    },
    deleteBranch() {
      const data = new FormData()
      data.append('branch_id', this.branchId)

      this.$axios.post(`${this.$baseUrl}/branch/delete_branch`, data).then(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Work location successfully deleted')}.`,
              icon: 'Trash2Icon',
              variant: 'success',
              iconBg: '#e80202',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        this.$bvModal.hide('delete-branch')
        this.getBranch()
      }).catch(err => {
        const msg = err.response.data.message
        // console.log('msg', msg)
        this.$toast(
          {
            component: SAJToast,

            props: {
              title: this.isEnglish ? msg.English : msg.Bahasa,
              icon: 'XIcon',
              iconBg: '#e80202',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        this.$bvModal.hide('delete-branch')
      })
    },
  },
}
</script>
<style>
</style>
