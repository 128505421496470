<template>
  <div class="">
    <div
      class=""
    >
      <div class="saj-header mb-1">
        {{ subsidiaryName }}
      </div>
      <div>
        <div
          class="d-flex flex-column mb-1"
        >
          <div
            class="saj-title mb-1 "
          >
            {{ $t("Employee Info") }}
          </div>
          <div
            class="saj-title d-flex align-items-end justify-content-end"
          >

            <input
              id="csvImport"
              type="file"
              style="visibility:hidden"
              @input="uploadCSV()"
            >
            <b-input-group class="mr-1">
              <b-form-input
                v-model="searchEmployee"
                class=""
                style="width: 100px;"
                :style="clearSearch ? `border-right: none` : ''"
                :placeholder="$t('Search Employee')"
                :disabled="selectedItem === null"
              />
              <b-input-group-append>
                <b-input-group-text
                  v-if="clearSearch"
                  style="border-right: solid 2px #d8d6de; border-left: none"
                >
                  <i
                    class="fa-solid fa-x pointer onHover"
                    @click="clearSearch = false, searchEmployee = null, getSubsidiaryStaff(), currentPage = 1 "
                  />
                </b-input-group-text>
                <b-input-group-text>
                  <i
                    class="fa-solid fa-magnifying-glass pointer onHover"
                    @click="clearSearch = true, currentPage = 1, getSubsidiaryStaff() "
                  />
                </b-input-group-text>

              </b-input-group-append>
              <template #prepend>
                <b-form-select
                  v-model="selectedItem"
                  :options="selectItem"
                  :value="selectItem"
                  style="border-radius: 0; width: 190px"
                />
              </template>
            </b-input-group>

            <b-button
              variant="primary"
              class="mr-1 d-flex align-items-center justify-content-center saj-button"
              style="width: 200px;"
              :href="`${publicPath}assets/excel_upload_template.xlsx`"
              download
            >
              {{ $t("Template") }}<span style="margin-left: 3px;">CSV</span>
            </b-button>
            <b-button
              variant="primary"
              style="width: 180px;"
              class="mr-1"
              @click="triggerImportCSV()"
            >
              {{ $t("Import CSV") }}
            </b-button>
            <b-button
              v-b-modal.new-employee
              variant="primary"
              style="width: 200px;"
            >
              {{ $t("Add Employee") }}
            </b-button>
          </div>
        </div>
      </div>
      <b-overlay
        :show="show"
        rounded="sm"
      >
        <template #overlay>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
            <p class="mt-1">
              {{ $t('Fetching Data') }}...
            </p>
          </div>

        </template>
        <!-- <p
          v-for="(a, index) in subsidiaryStaffList"
          :key="index"
        >{{ a }}</p> -->

        <b-table
          class="styleTable"
          style="font-size: 1rem;"
          responsive
          show-empty
          :items="subsidiaryStaffList"
          :fields="fields"
          :per-page="perPage"
          label-size="lg"
          bordered
          @filtered="onFiltered"
        >
          <template #head()="data">
            <span
              class="saj-text d-flex justify-content-center"
            >{{ $t(data.label) }}</span>
          </template>
          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>
          <template #emptyfiltered="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records matching your request') }}
            </h4>
          </template>
          <template #cell(index)="data">
            <div
              class="saj-text d-flex justify-content-center"
            >
              {{ currIdx === 1 ? data.index + 1 : (data.index + 1)+((currIdx-1) *perPage) }}
            </div>
          </template>
          <template #cell(employeeNo)="data">
            <div
              class="saj-text d-flex justify-content-center"
            >
              {{ data.item.employee_number }}
            </div>
          </template>
          <template #cell(name)="data">
            <div
              class="saj-text d-flex justify-content-start"
            >
              {{ data.item.full_name }}
            </div>
          </template>
          <template #cell(position)="data">
            <div
              class="saj-text d-flex justify-content-center"
            >
              {{ data.item.position }}
            </div>
          </template>
          <template #cell(department)="data">
            <div
              class="saj-text d-flex justify-content-center"
            >
              {{ data.item.department }}
            </div>
          </template>
          <template #cell(actions)="data">
            <div
              class="saj-text d-flex justify-content-center align-items-center"
              style=""
            >
              <!-- {{ data.item.user_id }} -->
              <!-- @click="empData = data.item" -->
              <feather-icon
                v-b-tooltip.hover.top="$t('Edit')"
                icon="EditIcon"
                size="25"
                style="color: green; cursor: pointer;"
                class="mr-1"
                @click="getEmployee(data.item)"
              />
              <feather-icon
                v-b-modal.deactivate-employee
                v-b-tooltip.hover.top="$t('Deactivate Employee')"
                icon="UserXIcon"
                size="25"
                style="color: red"
                class="mr-1"
                @click="employeeNum = data.item.employee_number"
              />
              <!-- <feather-icon
                v-b-modal.delete-employee
                v-b-tooltip.hover.top="$t('Delete')"
                icon="Trash2Icon"
                size="25"
                style="color: red"
                class="mr-1"
                @click="userId = data.item.user_id"
              /> -->
              <feather-icon
                v-b-tooltip.hover.top="$t('Email Invitation')"
                icon="MailIcon"
                size="25"
                class=""
                style="cursor: pointer;"
                :style="data.item.invitation === 1 ? `color: grey` : `color: black`"
                :disabled="data.item.invitation === 1"
                @click="sendInvitationEmail(data.item.email)"
              />
              <feather-icon
                v-if="roles.isHR || roles.isAdmin"
                v-b-modal.reset-password
                v-b-tooltip.hover.top="$t('Reset Password')"
                icon="UnlockIcon"
                size="25"
                style="color: blue; cursor: pointer;"
                class="ml-1"
                @click="email = data.item.email"
              />
            </div>
          </template>
          <template #cell(roles)="data">
            <div
              class="saj-text"
              style="width: 100%"
            >
              <b-badge
                v-for="index in data.item.role"
                :key="index.a"
                style="margin-left: 5px; margin-top: 5px;"
                class=""
                variant="light-primary"
                pill
              >
                {{ $t(index.role_name) }}
              </b-badge>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <span
              class="saj-text"
              align="start"
            >
              {{ $t("Showing") }} {{ from }} {{ $t("to") }} {{ to }} {{ $t("of") }} {{ rows }} {{ $t("entries") }}
            </span>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center justify-content-end">
              <i
                style="margin-right: 5px"
                class="fa-solid fa-angles-left saj-page"
                @click="currentPage = 1, getSubsidiaryStaff()"
              />
              <i
                class="fa-solid fa-angle-left saj-page"
                style=""
                @click="currentPage !== 1 ? currentPage-- : currentPage === 1, getSubsidiaryStaff()"
              />
              <validation-provider
                #default="{ errors }"
                :name="$t('Page')"
                :rules="{
                  required,
                  max_value:lastPage,
                  min_value:1,
                }"
              >
                <b-form-input
                  v-model="currentPage"
                  class="p-0 text-center"
                  type="number"
                  style="width: 35px; height: 35px; background-color: red; color: white; border-radius: 20px; margin-right: 5px; margin-left: 5px;"
                  @change="currentPage > 0 && currentPage < lastPage + 1 ? getSubsidiaryStaff() : ''"
                />
                <small
                  class="text-danger"
                  style="color: #EA5455 !important;
                          position: absolute;
                          white-space: nowrap;
                          right: 25px;
                        "
                >{{ errors[0] }}</small>
              </validation-provider>
              <i
                class="fa-solid fa-angle-right saj-page"
                style=""
                @click="currentPage < lastPage ? currentPage++ : currentPage === rows, getSubsidiaryStaff()"
              />
              <i
                class="fa-solid fa-angles-right saj-page"
                style="margin-left: 5px"
                @click="currentPage = lastPage, getSubsidiaryStaff()"
              />
            </div>
          </b-col>
        </b-row>
      </b-overlay>

      <b-modal
        id="new-employee"
        :hide-header="true"
        :hide-footer="true"
        :centered="true"
        size="lg"
      >
        <!-- $bvModal.hide(nama modal id) untuk tutup modal-->
        <emp-info
          :emp-info="{}"
          :subs-i-d="subsidiaryID"
          :is-update="false"
          @close="$bvModal.hide('new-employee')"
          @add-employee="getSubsidiaryStaff(), $bvModal.hide('new-employee')"
        />
      </b-modal>
      <b-modal
        id="update-employee"
        :hide-header="true"
        :hide-footer="true"
        :centered="true"
        size="lg"
      >
        <!-- $bvModal.hide(nama modal id) untuk tutup modal-->
        <emp-info
          :emp-info="empData"
          :is-update="true"
          :subs-i-d="subsidiaryID"
          @close="$bvModal.hide('update-employee')"
          @update-employee="getSubsidiaryStaff(), $bvModal.hide('update-employee'), show = true"
        />
      </b-modal>
      <b-modal
        id="deactivate-employee"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
      >
        <AlertModal
          :title="$t('Are you sure to deactivate this employee?')"
          :confirm-btn="$t('Yes')"
          @cancel="$bvModal.hide('deactivate-employee')"
          @confirm="deactivateEmployee(), $bvModal.hide('deactivate-employee')"
        />
      </b-modal>
      <!-- <b-modal
        id="delete-employee"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
      >
        <delete-emp
          @cancel="$bvModal.hide('delete-employee')"
          @confirm="deleteEmp()"
        />
      </b-modal> -->
      <b-modal
        id="reset-password"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
      >
        <AlertModal
          :title="$t('Are you sure to reset password ?')"
          @cancel="$bvModal.hide('reset-password')"
          @confirm="resetUserEmail(email), $bvModal.hide('reset-password')"
        />
      </b-modal>
      <b-modal
        id="errMsg"
        :ok-only="true"
        :hide-header="true"
        :centered="true"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :footer-class="`d-flex justify-content-center`"
        size="lg"

        @ok="errorMsg = []"
      >
        <div class="p-1">
          <h4 class="mb-1">
            {{ $t('Your Excel file failed to upload. Please fix according to the error(s) listed in the table below') }}.
          </h4>
          <b-table
            show-empty
            :items="errorMsg"
            :fields="errFields"
            responsive=""
            bordered
            class="mt-1 mb-1 saj-subtitle"
          >
            <template #head()="data">
              <span
                class="saj-title"
                style=""
              >{{ $t(data.label) }}</span>
            </template>

            <template #empty="">
              <h4 style="text-align: center; font-style: italic">
                {{ $t('There are no records to show') }}
              </h4>
            </template>

            <template #cell(line)="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                {{ data.item.line }}
              </div>
            <!-- <div class="saj-text d-flex justify-content-center">
              {{ data.item.errors }}
            </div> -->
            </template>

            <template #cell(errors)="data">
              <div v-if="Array.isArray(lang === 'en' ? data.item.errors_bi : data.item.errors_bm)">
                <div
                  v-for="(a, index) in (lang === 'en' ? data.item.errors_bi : data.item.errors_bm)"
                  :key="index"
                  class="saj-text d-flex justify-content-start"
                >
                  {{ index +1 }}. {{ a }}
                </div>
              </div>

              <div
                v-else
                class="saj-text"
              >

                {{ lang === 'en' ? data.item.errors_bi : data.item.errors_bm }}
                <div
                  v-for="(a, idx) in data.item.data"
                  :key="idx"
                  class="saj-text d-flex justify-content-start"
                >
                  {{ idx +1 }}. {{ a }}
                </div>
              </div>
            </template>
          </b-table>
        </div>

      </b-modal>
    </div>

  </div>

</template>
<style>
.customClass{
  text-decoration: none;
  padding: 0px;
  margin: 0px;
}
</style>

<script>
import {
  BOverlay,
  BSpinner,
  BButton,
  // BLink,
  // BFormFile,
  BTable,
  VBTooltip,
  BBadge,
  BFormInput,
  // BFormGroup,
  // BPagination,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  // BDropdown,
  // BDropdownItem,
  BFormSelect,
} from "bootstrap-vue"

import { required } from '@validations'
// import flatPickr from "vue-flatpickr-component"
import empInfo from "@/component/employee-info-modal.vue"
// import deleteEmp from "@/pages/alert-modal.vue"
import AlertModal from "@/pages/alert-modal.vue"
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'
import {
  max_value,
  min_value,
} from 'vee-validate/dist/rules'
import { ValidationProvider, extend } from 'vee-validate'

extend('max_value', max_value)
extend('min_value', min_value)

export default {
  components: {
    BOverlay,
    BSpinner,
    // BLink,
    BButton,
    BTable,
    empInfo,
    // deleteEmp,
    BBadge,
    // BFormGroup,
    BFormInput,
    // BPagination,
    BRow,
    BCol,
    ValidationProvider,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    // BDropdown,
    // BDropdownItem,
    BFormSelect,
    AlertModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    subsID: {
      type: String,
      default: "0",
    },
    subsidiaryName: {
      type: String,
      default: "",
    },
  },
  data(){
    return {
      lang: null,
      clearSearch: false,
      errorMsg: [],
      email: null,
      show: true,
      searchEmployee: null,
      publicPath: process.env.BASE_URL,
      emailList: [],
      userId: null,
      subsidiaryID: null,
      subsidiaryStaffList: [],
      empData: {},
      employeeList: [],
      required,
      currentPage: 1,
      perPage: null,
      to: null,
      from: null,
      rows: null,
      lastPage: null,
      currIdx: null,
      selectedItem: null,
      employeeNum: null,
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'employeeNo', label: 'Employee No.', thClass: 'text-center',
        },
        {
          key: 'name', label: 'Name', tdClass: 'text-center',
        },
        {
          key: 'position', label: 'Position', tdClass: 'text-center',
        },
        {
          key: 'department', label: 'Department', tdClass: 'text-center',
        },
        {
          key: 'actions', label: 'Action', tdClass: 'text-center',
        },
        {
          key: 'roles', label: 'Role(s)', tdClass: 'text-center',
        },
      ],
      errFields: [
        {
          key: 'line', label: 'Excel Row No', tdClass: 'text-center',
        },
        {
          key: 'errors', label: 'Error List', tdClass: 'text-left',
        },
      ],
      selectItem: [
        { value: null, text: `${this.$t('Search by')}` },
        { value: 'Id', text: `${this.$t('Employee Number')}` },
        { value: 'Name', text: `${this.$t('Employee Name')}` },
      ],
    }
  },

  computed: {
    ...mapGetters(['roles']),
  },
  watch: {

    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.lang = this.$i18n.locale
      // console.log('lang', this.lang)
      this.selectItem = this.selectItem.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Search by'),
          }
        }
        return x
      })
    },

  },
  mounted() {
    this.subsidiaryID = this.subsID

    this.lang = this.$i18n.locale

    this.onInitial()
  },
  methods: {
    onFiltered(filteredItems){
      this.rows = filteredItems.length
    },
    onInitial() {
      this.getSubsidiaryStaff()
    // this.getEmployee()
    },
    onHideModal(){
      this.empData = {}
    },
    triggerImportCSV() {
      document.getElementById('csvImport').value = null
      document.getElementById('csvImport').click()
    },
    getSubsidiaryStaff(){
      const params = new URLSearchParams()

      params.append('subsidiary_id', this.subsidiaryID)
      params.append('page', this.currentPage)
      if (this.searchEmployee !== null){
        if (this.selectedItem === 'Name'){
          params.append('employee_name', this.searchEmployee)
        } else {
          params.append('employee_number', this.searchEmployee)
        }
      }
      params.append('status', 'active')

      this.show = true
      this.subsidiaryStaffList = []
      // this.$axios.get(`${this.$baseUrl}/subsidiary/get_subsidiary_staff?subsidiary_id=${this.subsidiaryID}&page=${this.currentPage}`).then(async response => {
      this.$axios.get(`${this.$baseUrl}/subsidiary/get_subsidiary_staff`, { params }).then(async response => {
        // params.forEach(a => {
        //   console.log('append', a)
        // })
        const result = response.data
        // console.log('result', response)
        if (result !== undefined && result.success !== false){
          let tempList = result.data
          this.from = result.from
          this.to = result.to
          this.rows = result.total
          this.perPage = result.per_page
          this.lastPage = result.last_page
          this.currIdx = this.currentPage

          tempList = await Promise.all(tempList.map(x => this.$axios({
            method: 'get',
            url: `${this.$baseUrl}/roles/getUserRole?user_id=${x.user_id}`,
          })
            .then(async res => ({
              ...x,
              role: res.data.data.role,
            }))
            .catch(() => {
            })))
          this.subsidiaryStaffList = tempList
          // this.rows = this.subsidiaryStaffList.length
        } else {
          // this.$toast(
          //   {
          //     component: SAJToast,
          //     props: {
          //       title: result.message,
          //       icon: 'XIcon',
          //       iconBg: '#e80202',
          //       variant: 'success',
          //       titleColor: '#000',
          //     },
          //   },
          //   {
          //     position: "top-right",
          //     type: 'error',
          //   },
          // )
          this.to = 0
          this.from = 0
          this.rows = 0
        }
        this.show = false
      }).catch(() => {

      })
    },
    uploadCSV() {
      const file = document.getElementById('csvImport').files[0]

      const data = new FormData()
      data.append('file', file)
      // console.log('file', file)
      // eslint-disable-next-line no-unused-vars
      const config = {
        method: 'post',
        url: `${this.$baseUrl}/hr/getFile`,
        data,
      }

      this.$axios(config)
        .then(res => {
          // console.log('file res', res)
          if (res.data.success === true){
             this.getSubsidiaryStaff()
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: res.data.message,
                  icon: "InfoIcon",
                  iconColor: 'white',
                  iconBg: '#53b7db',
                  // iconBg: 'rgb(255,0,15, 1)',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
           
          } else {
            const val = res.data.data

            // console.log('err', val)

            val.forEach(k => {
              this.errorMsg.push({
                line: k.line !== undefined ? k.line : '-',
                errors_bm: k.error_bm,
                errors_bi: k.error_bi,
                data: k.data !== undefined ? k.data : null,
              })
            })
            this.$bvModal.show('errMsg')
          }
        }).catch(() => {

        })
    },
    getEmployee(item){
      // console.log(item.user)
      this.$axios.get(`${this.$baseUrl}/employees/getEmployeeByUserId?user_id=${item.user_id}&status=active`).then(response => {
        const result = response.data
        if (result.success){
          this.empData = response.data.data
          this.$bvModal.show('update-employee')
          // this.$bvModal.show('new-employee')
          // console.log('ini', this.empData)
        }
      })
    },
    deactivateEmployee() {
      const data = new FormData()
      data.append('employee_number', this.employeeNum)
      data.append('status', 'inactive')

      this.$axios.post(`${this.$baseUrl}/employees/setActiveInactiveEmployee`, data).then(() => {
        this.$bvModal.hide('delete-employee')
        this.currentPage = 1
        this.searchEmployee = null
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Successfully Deactivate Employee')}.`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        this.getSubsidiaryStaff()
      })
    },
    // deleteEmp() {
    //   const data = new FormData()
    //   data.append('user_id', this.userId)

    //   this.$axios.post(`${this.$baseUrl}/employees/deleteEmployee`, data).then(() => {
    //     this.$bvModal.hide('delete-employee')
    //     this.currentPage = 1
    //     this.searchEmployee = null
    //     this.$toast(
    //       {
    //         component: SAJToast,
    //         props: {
    //           title: `${this.$t('Successfully deleted')}.`,
    //           icon: 'CheckCircleIcon',
    //           variant: 'success',
    //           titleColor: '#000',
    //         },
    //       },
    //       {
    //         position: "top-right",
    //         type: 'info',
    //       },
    //     )
    //     this.getSubsidiaryStaff()
    //   })
    // },
    sendInvitationEmail(email){
      const data = new FormData()
      data.append('email', email)

      const config = {
        method: 'post',
        url: `${this.$baseUrl}/users/send_invitation`,

        data,
      }

      this.$axios(config)
        .then(() => {
          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Successfully send Invitation Email')}.`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
          }, 1000)
        })
        .catch(() => {
        })
    },
    resetUserEmail(email){
      const data = new FormData()
      data.append('email', email)

      const config = {
        method: 'post',
        url: `${this.$baseUrl}/password/change/random`,

        data,
      }

      this.$axios(config)
        .then(() => {
          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Successfully reset user Email')}.`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
          }, 1000)
          this.$bvModal.hide("reset-password")
        })
        .catch(() => {
        })
    },
  },
}
</script>
<style>
.onHover:hover{
  color: red;
  text-shadow: 0px 0px 4px red;
}
</style>
